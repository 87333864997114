<template>
  <v-card class="elevation-0" color="bg">
      <v-card-text>
        <v-form @keyup.native.enter="login">
          <v-text-field :label="$t('login.form.username')" v-model="username" prepend-icon="mdi-account" type="text" />
          <v-text-field :label="$t('login.form.password')" v-model="password" prepend-icon="mdi-lock" type="password" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" class="black--text"  @click="login()">{{ $t("login.form.submit") }}</v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import { startLogoutTimer } from "../../helpers/timeout.js";

export default {
  name: "LoginForm",
  data() {
    return {
      showLogin: true,
      username: "",
      password: "",
      resetmail: "",
    };
  },
  computed: {},
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          username: this.username,
          password: this.password,
        })
        .then((u) => {
          this.$root.$i18n.locale = u.lang;
          let redirectUrl = "/";

          if (this.$route.query.redirect) {
            redirectUrl = this.$route.query.redirect;
          }

          startLogoutTimer();

          this.$router.push(redirectUrl);
        })
        .catch((error) => {
          let err = {
            message: "Undefined Error",
            color: "red",
          };
          if (error.data && error.data.message) {
            err.message = error.data.message;
          }

          this.$store.dispatch("showSnackMessage", err);
        });
    },
    submitReset() {
      axios
        .get(`/api/app/sendpwdresetrequest?email=${this.resetmail}`)
        .then(() => {
          this.$store.dispatch("showSnackMessage", { message: "Password reset request has been sent." });
        })
        .catch(() => {
          this.$store.dispatch("showSnackMessage", { message: "Error sending mail. Please try again." });
        });
      this.resetmail = "";
      this.showLogin = true;
    },
  },
};
</script>
